import React from "react";

import imgC from "../../assets/image/inner-page/webp/bookmarking.webp";
import imgD from "../../assets/image/inner-page/webp/bookmarking-mobile.webp";
import imgE from "../../assets/image/inner-page/webp/bookmarking-email.webp";

const CTA  = ({ className, ...rest }) => {

  return (
    <div className={className} {...rest}>
      <div className="pt-14 pt-md-19 pt-lg-10 pb-15 pb-md-18 pb-lg-25 mb-lg-1">
      <div className="container">
      <div className="row align-items-center justify-content-center">
      <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11 text-center"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <h3 className="font-size-9 mb-10">Desktop UX</h3>
            <img src={imgC} alt="Bookmarking" className="w-100 rounded-5 box-shadow" />
          </div>
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-15 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <p
                className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >Bookmarking is a great way to keep track of great content.</p>
              <p
                className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >When the user sees something they like they can bookmark that point in the video and Digma can send them a summary reminder. </p>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>When the user returns to the video they can continue straight from where they left off.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-10 mt-lg-20">
          <div className="col-lg-6 col-md-10 col-sm-11 text-center">
              <h3 className="font-size-9 mb-10 mt-10">Bookmarks summary reminder</h3>
              <img src={imgE} alt="Bookmarking" className="w-100 rounded-5  box-shadow" />
          </div>
          <div
            className="col-xl-6 col-lg-5 col-md-10 col-sm-11 text-center"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <h3 className="font-size-9 mb-10 mt-10">Mobile UX</h3>
            <img src={imgD} alt="Bookmarking Mobile" className="w-50 box-shadow rounded-5 mobilefull" />
            </div>          
        </div>
      </div>
    </div>
    </div>
  );
};

export default CTA;
